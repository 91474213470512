.footer-main {
    background: #000000;
    /* position: fixed;
    z-index: 99;
    bottom: 0; */
    width: 100%;
}

.social-footer-sec {
    background: #fff !important;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.5) !important;
}

.footer-micro a {
    color: #ffc107 !important;
    text-decoration: none;
}

.whatapp-chat {
    padding: 4px;
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 8%;
    left: 31px;
    background-color: #0dc143;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 23px;
    /* box-shadow: 2px 2px 3px #999; */
    z-index: 100;
    animation: animatewchat 1s infinite ease;
}

.btn-whatsapp-pulse {
    background: #25d366;
    color: white;
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 0;
    padding: 30px;
    text-decoration: none;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }
    80% {
        box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
    }
}


/* @keyframes animatewchat {
    to {
        width: 45px;
    }
    from {
        width: 100px;
    }
} */

.mail-chat {
    padding: 4px;
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 43%;
    left: 2px;
    background-color: #2184f4;
    color: #FFF;
    /* border-radius: 50px; */
    text-align: center;
    font-size: 23px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.phone-chat {
    padding: 6px;
    position: fixed;
    width: 45px;
    height: 45px;
    bottom: 38%;
    left: 2px;
    background-color: #f41212;
    color: #FFF;
    /* border-radius: 50px; */
    text-align: center;
    font-size: 23px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.common-btn-betch {
    position: fixed;
    right: 0px;
    top: 50%;
    z-index: 999 !important;
    transform: translate(0, -50%);
}

.footer-links {
    text-decoration: none !important;
    color: white;
}

.footer-links:hover {
    color: rgb(165 44 2) !important;
}

.footer-address {
    display: flex;
}

@media (max-width:1000px) {
    .footer-address {
        display: block !important;
    }
}