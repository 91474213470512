.slider-container {
    padding: 0;
    /* Remove any padding that may affect the full-width display */
    max-width: 100%;
    width: 100%;
}


/* Optional: If you want to center the images vertically, you can add the following CSS */

.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
}


/*     background: linear-gradient(45deg, #03a9f5, #e7aaaa); */

.imgText {
    color: white;
    text-shadow: 2px 2px #1674b9;
}

.css-1ri6ub7-MuiPaper-root-MuiCard-root {
    height: 100% !important;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1) !important;
}


/* pravin css */

.box1 {
    height: 100%;
    width: 100%;
    background-color: #03a9f5;
    padding: 50px;
}

.box2 {
    height: 100%;
    width: 100%;
    background-color: #fdfcfc;
    padding-left: 50px;
    padding-bottom: 40px;
}

.main_section {
    display: flex;
}

.heading {
    padding-top: 10%;
}

.img1 {
    display: flex;
    height: 200px;
}

.image2 {
    margin-left: 12%;
    padding-right: 10%;
}

.img2 {
    display: flex;
    margin-top: 10%;
}

#slidingImage {
    position: relative;
    width: fit-content;
}

.text-block p {
    position: absolute;
    bottom: 20%;
    left: 40%;
    background-color: black;
    color: white;
    text-align: center;
}

.btn_call {
    background-color: white;
    color: #03a9f5;
    border: 2px solid #03a9f5;
    border-radius: 22px;
    padding: 8px 15px;
}

.image_hover {
    transition: 1s;
}

.image_hover:hover {
    -webkit-transform: translateX(-15px) translateY(13px) scale(1.1);
    -moz-transform: translateX(-15px) translateY(13px) scale(1.1);
    -o-transform: translateX(-15px) translateY(13px) scale(1.1);
    -ms-transform: translateX(-15px) translateY(13px) scale(1.1);
    transform: translate(-1px) translateY(-7px) scale(1.2);
}

@media (max-width:900px) {
    .main_section {
        display: inline-block !important;
    }
    .img1 {
        display: inline-block !important;
        margin-left: 0px;
    }
    .img2 {
        display: inline-block !important;
    }
    .image2 {
        margin-left: 0% !important;
    }
    .about-call-btn {
        text-align: center !important;
    }
}

.main {
    display: flex;
    flex-wrap: wrap;
}

.box {
    text-align: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
    width: 100%;
    height: 100%;
}

.box1 {
    background: rgb(165 44 2);
    color: white;
}

.link_color {
    font-weight: bold;
    color: rgb(165 44 2);
}

.browse_profile {
    border: 1px solid rgb(165 44 2);
    background: white;
    font-weight: bold;
    color: rgb(165 44 2);
    border-radius: 5px;
    font-size: 13px;
    padding: 10px;
}

.browse_profile:hover {
    background: rgb(165 44 2);
    color: white;
    border: none;
}

.achieve {
    display: flex;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
    width: 100%;
}

.achieve_img {
    border-radius: 50px !important;
    height: 80px;
}

.achieve h4 {
    border-bottom: 1px solid rgb(165 44 2);
    border-width: 2px;
    width: 38%;
}

.img_para {
    display: flex;
}

.img_para svg {
    color: rgb(165, 44, 2);
    border: 2px solid rgb(165, 44, 2);
    border-radius: 60px;
    padding: 1.5%;
}

.img_para svg:hover {
    background-color: rgb(165, 44, 2);
    color: white;
}

.color_heading {
    color: rgb(165 44 2);
    font-weight: bold;
}

.card_services {
    text-align: center;
    /* border: 1px solid #d5e1ed; */
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
}

.card_services:hover {
    background-color: rgb(165, 44, 2);
    color: white;
}

.common-services-box:hover .card_services .icon-box .icon {
    background: rgb(152, 152, 152);
}

.icon {
    width: 9vh;
    height: 8vh;
    background: #c53509;
    border: #c53509;
    margin-bottom: 20px;
    border-radius: 10px 0px;
    margin-left: 35%;
}

.svg_icon {
    margin: 0.7rem;
    top: 9%;
    padding: .6rem;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 0px #d6d6d6;
    background: rgb(255, 255, 255);
    left: 4.5%;
    color: white;
}

@media (max-width:567px) {
    .achieve {
        display: inline-block;
    }
    .achieve img {
        margin-left: 20%;
        margin-bottom: 15px;
    }
    .img_para {
        display: inline-block;
    }
}