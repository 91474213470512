.contact-info-para {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 18px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.contact-info-left {
    background: white;
    padding: 1rem;
    border: none !important;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.common-contact-icon-group {
    background: #ffe1bf;
    color: rgb(197 53 9);
}

.common-contact-icon-group:hover {
    background: rgb(197 53 9);
    color: #ffe1bf;
}

.icon-contact-third {
    width: 50px;
    border-radius: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-bt-style {
    width: 20vh;
    background: black;
    height: 1px;
    animation: textInfinite 3s ease infinite;
}

@keyframes textInfinite {
    to {
        width: 0vh;
    }
    from {
        width: 20vh;
    }
}

.icon-contact {
    width: 87px;
    border-radius: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-contact-second {
    width: 50px;
    border-radius: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-card {
    padding: 1rem;
    border: none !important;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    /* box-shadow: 0px 1px 10px 0px #03a9f5; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (max-width:1000px) {
    .contact-info-para {
        display: flex;
        flex-direction: column;
        /* justify-content: start !important;
        align-items: start; */
    }
    .contact-info-para .number-contact-span {
        margin-left: 0 !important;
    }
    .contact-info-left {
        margin-bottom: 30px;
    }
    .icon-contact,
    .icon-contact-second,
    .icon-contact-third {
        width: 50px;
        margin-bottom: 10px;
    }
}

@media (min-width:1100px) and (max-width:1400px) {
    .icon-contact {
        width: 104px !important;
        border-radius: 50%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}