.img-list .list {
    border: 5px solid transparent;
    box-sizing: border-box;
    width: 100%;
    float: left;
    position: relative;
    cursor: pointer;
}

.img-list .list:before {
    transition: all .5s ease;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #333;
    transform: scale(0);
}

.img-list .list:hover:before {
    opacity: .5;
    transform: scale(1);
}

.img-list .list:after {
    transition: all .6s ease .2s;
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border: 1px solid #aaa;
    background: #000;
    opacity: 0;
    transform: scale(0);
}

.img-list .list:hover:after {
    opacity: .35;
    transform: scale(1);
}