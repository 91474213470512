@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&display=swap');
* {
    padding: 0;
    margin: 0;
}

body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'Poppins', sans-serif !important;
}

.heading_line {
    width: 3%;
    height: 3%;
    margin: 0;
    margin-bottom: 10px;
    position: relative;
    border: none;
    background: rgb(0, 0, 0) !important;
    transition: 2s;
}

.heading_line:hover {
    width: 7%;
    height: 3%;
    margin: 0;
    margin-bottom: 10px;
    position: relative;
    border: none;
    background: rgb(0, 0, 0) !important;
}

.common-top-bg {
    height: 300px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.common-text {
    background: linear-gradient(to right, #a52c02, #fe5419, #d99f07, #f17122);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; */
    font-weight: 700;
}