/* Add your preferred styles for the button */

.scroll-to-top-button {
    position: fixed;
    bottom: 10%;
    right: 20px;
    display: block;
    border: none;
    cursor: pointer;
    animation: scrollAni ease-in-out 1s infinite;
}

@keyframes scrollAni {
    to {
        bottom: 10%;
    }
    from {
        bottom: 12%;
    }
}