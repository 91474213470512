@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&display=swap');
.about-card {
    border: none !important;
    height: 100% !important;
    box-shadow: 0px 1px 10px 0px #d6d6d6;
}

.card-about-last {
    border: none !important;
    height: 100% !important;
    box-shadow: 0px 1px 10px 0px #d6d6d6;
}

.about-our-vision {
    height: 100% !important;
}

.icon-group {
    transition: 1s;
    width: 14vh;
}

.icon-group:hover {
    transform: scale(1.1);
}

.about-icon-common {
    width: 9vh;
    height: 8vh;
    background: #c53509;
    border: #c53509;
    margin-bottom: 20px;
    border-radius: 10px 0px;
}

.on-icon-img {
    position: absolute!important;
    top: 9%;
    padding: .6rem;
    /* background: rgb(118, 118, 118); */
    box-shadow: 0px 1px 10px 0px #d6d6d6;
    background: rgb(255, 255, 255);
    left: 4.5%;
}

.about-card-body {
    height: 100% !important;
}

.about-left-sec {
    background: transparent !important;
}

.about-left-sec p {
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    font-family: "Poppins";
}

.about-right-third ul li {
    margin-bottom: 10px;
}

.about-right-third ul li::marker {
    color: rgb(19 113 189);
}

@media (max-width:600px) {
    /* .about-bg {
        display: none !important;
    } */
    .about-us-vision-mision {
        margin-bottom: 30px;
    }
    .on-icon-img {
        position: absolute!important;
        top: 8%;
        padding: 0.6rem;
        /* background: rgb(118, 118, 118); */
        box-shadow: 0px 1px 10px 0px #d6d6d6;
        background: rgb(255, 255, 255);
        left: 9.5%;
    }
}